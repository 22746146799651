<template>
  <view-item title="盘点调价">
    <template #operation>
      <en-button type="primary" @click="operation.add.click">创建单据</en-button>

      <button-ajax v-if="form.data.id" :disabled="form.disabled || form.loading" :method="operation.submit.click"> 保存 </button-ajax>

      <button-ajax v-if="form.data.id" :disabled="form.disabled || form.loading" :method="operation.commit.click"> 保存并提交 </button-ajax>

      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="logs">单据历史</en-dropdown-item>
          <en-dropdown-item command="export">导出</en-dropdown-item>
          <en-dropdown-item v-if="form.data.status?.code === 'P'" command="delete">删除</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        :ajax="{ action: 'GET /enocloud/stock/cost/adjustment', params: (params) => (params.payload = { rsexp: 'id,serialNo,status' }) }"
        :props="{ start: 'startDate', end: 'endDate' }"
        :routes="[
          { path: '/accessory/inventory/adjustment', name: '调价单' },
          { path: '/accessory/stock/taking', name: '盘点单' }
        ]"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
      >
        <en-table-column label="单号" prop="serialNo"></en-table-column>
        <en-table-column label="状态" prop="status.message"></en-table-column>

        <template #form="{ data }">
          <en-form-item label="调价单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
          <en-form-item label="单据日期">
            <en-date-picker v-model:start="data.startDate" v-model:end="data.endDate" type="daterange"></en-date-picker>
          </en-form-item>
          <en-form-item label="仓库">
            <select-maintain
              v-model="data.warehouseId"
              :ajax="{
                action: 'GET /enocloud/common/warehouse',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="制单人">
            <select-maintain
              v-model="data.preparedById"
              :ajax="{
                action: 'GET /enocloud/common/user',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              remote
              multiple
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="经手人">
            <select-maintain
              v-model="data.auditById"
              :ajax="{
                action: 'GET /enocloud/common/user',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              remote
              multiple
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="备注">
            <en-input v-model="data.comment"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <div class="flex gap-4">
          <span>业务类型：{{ form.data.serialNo }}</span>
          <span>盘点仓库：{{ form.data.warehouse?.name }}</span>
        </div>

        <div class="flex items-center gap-6">
          <span>{{ form.data.preparedBy?.name }}</span>
          <en-tag>{{ form.data.status?.message }}</en-tag>
          <span>单号：{{ form.data.serialNo }}</span>
          <span>{{ formatDate(form.data.preparedDatetime) }}</span>
          <span>{{ form.data.comment }}</span>
        </div>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane
                label="配件"
                name="goods"
                :badge="form.data.stockCostAdjustmentGoods?.length"
                :style="{ height: height - 55 + 'px', overflow: 'auto' }"
                class="gap-6"
              >
                <div class="flex gap-4">
                  <select-maintain
                    v-if="form.data.warehouse?.id"
                    :ajax="{
                      action: 'GET /enocloud/inventory',
                      params: (params, value) => (params.payload = { name: value, inMaterialOnly: 'Y', warehouseId: form.data.warehouse?.id })
                    }"
                    :props="{
                      label: 'goods.name',
                      value: 'goods',
                      disabled: (option: EnocloudInventoryDefinitions['InventoryDto']) =>
                        Boolean(form.data.stockCostAdjustmentGoods?.find((item) => item.goods?.id === option.goods?.id))
                    }"
                    value-key="id"
                    placeholder="请选择配件"
                    remote
                    class="w-110"
                    :disabled="form.disabled"
                    @change="form.stockCostAdjustmentGoods.operation.goods.change"
                  ></select-maintain>

                  <en-button type="primary" :disabled="form.disabled" @click="form.stockCostAdjustmentGoods.operation.batch.click">
                    批量添加配件
                  </en-button>
                </div>

                <flex-box>
                  <template #default="{ height }">
                    <table-dynamic code="SCADJF" :data="form.data.stockCostAdjustmentGoods" :height="height">
                      <template #OPERATION="{ $index }: { $index: number }">
                        <en-button
                          type="primary"
                          link
                          :disabled="form.disabled"
                          @click="form.stockCostAdjustmentGoods.operation.delete.click($index)"
                        >
                          删除
                        </en-button>
                      </template>

                      <template #UNIT="{ row }: { row: EnocloudInventoryDefinitions['StockCostAdjustmentGoodsDto'] }">
                        <select-maintain
                          :model-value="row.goodsSpecification"
                          :options="row.goods?.specifications"
                          :props="{ label: 'name', value: '' }"
                          :clearable="false"
                          :disabled="form.disabled"
                          value-key="id"
                          @change="form.stockCostAdjustmentGoods.goodsSpecification.change(row, $event)"
                        ></select-maintain>
                      </template>

                      <template #BATCH_NO="{ row }: { row: EnocloudInventoryDefinitions['StockCostAdjustmentGoodsDto'] }">
                        <select-maintain
                          v-model="row.batch"
                          :options="row.goods?.batches"
                          :props="{ label: 'batchNo', value: '' }"
                          :clearable="false"
                          value-key="id"
                          :disabled="form.disabled"
                        ></select-maintain>
                      </template>

                      <template #ADJUSTED_PRIME_COST="{ row }: { row: EnocloudInventoryDefinitions['StockCostAdjustmentGoodsDto'] }">
                        <en-input-number v-model="row.adjustedPrimeCost" :min="0" :disabled="form.disabled" class="w-full"></en-input-number>
                      </template>

                      <template #DIFFERENCE="{ row }: { row: EnocloudInventoryDefinitions['StockCostAdjustmentGoodsDto'] }">
                        <en-input-number v-model="row.difference" :min="0" :disabled="form.disabled" class="w-full"></en-input-number>
                      </template>

                      <template #COMMENT="{ row }: { row: EnocloudInventoryDefinitions['StockCostAdjustmentGoodsDto'] }">
                        <en-input v-model="row.comment" :disabled="form.disabled"></en-input>
                      </template>
                    </table-dynamic>
                  </template>
                </flex-box>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <inventory-adjustment-dialog v-model="dialog.visible"></inventory-adjustment-dialog>

  <inventory-adjustment-detail v-model="detail.visible" :data="form.data" @confirm="detail.confirm"></inventory-adjustment-detail>

  <inventory-adjustment-logs v-model="logs.visible" :data="form.data"></inventory-adjustment-logs>

  <inventory-goods-select
    v-model="select.visible"
    type="adjustment"
    @confirm="select.confirm"
    :params="{ inMaterialOnly: 'Y', warehouseId: form.data.warehouse?.id }"
  ></inventory-goods-select>
</template>

<script lang="ts">
import InventoryAdjustmentDetail from '@accessory/components/inventory-adjustment-detail.vue'
import InventoryAdjustmentDialog from '@accessory/components/inventory-adjustment-dialog.vue'
import InventoryAdjustmentLogs from '@accessory/components/inventory-adjustment-logs.vue'

import { InventoryGoodsSelect, type GoodsSelectionOption } from '@enocloud/business'

const formDataGoodsInit = (props: Partial<EnocloudInventoryDefinitions['StockCostAdjustmentGoodsDto']>) => {
  return Object.assign(
    { originalAmount: 0, originalCount: 0, originalPrimeCost: 0 },
    props
  ) as EnocloudInventoryDefinitions['StockCostAdjustmentGoodsDto']
}

export default factory({
  components: { InventoryAdjustmentDetail, InventoryAdjustmentDialog, InventoryAdjustmentLogs, InventoryGoodsSelect },

  config: {
    children: {
      operation: {
        add: {
          click() {
            this.form.init()
            this.detail.visible = true
          }
        },
        submit: {
          async click() {
            await this.form.submit()
            return this.form.get()
          }
        },
        commit: {
          async click() {
            await this.form.submit()
            await this.form.audit()
            return this.form.get()
          }
        },
        option: {
          async command(option: string) {
            await this.dirtyCheck('form')
            switch (option) {
              case 'reject':
                break
              case 'logs':
                this.logs.visible = true
                break
              case 'export':
                break
              case 'delete':
                await this.form.delete()
                this.form.init()
                break
            }
          }
        }
      },
      manifest: {
        row: {
          click(row: EnocloudInventoryDefinitions['StockCostAdjustmentDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      tabs: {
        active: 'goods'
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/stock/cost/adjustment/:stockCostAdjustmentId',
            data: 'object',
            init: true,
            loading: true,
            dirty: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'PUT /enocloud/stock/cost/adjustment',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          audit: {
            action: 'POST /enocloud/stock/cost/adjustment/:stockCostAdjustmentId/audit',
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          delete: {
            action: 'DELETE /enocloud/stock/cost/adjustment/:stockCostAdjustmentId',
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        computed: {
          summary() {
            return {
              count: 0,
              amount: 0
            }
          },
          disabled() {
            return this.form.data.status?.code === 'A'
          }
        },
        children: {
          stockCostAdjustmentGoods: {
            operation: {
              goods: {
                change(value: EnocloudCommonDefinitions['GoodsDto']) {
                  this.form.data.stockCostAdjustmentGoods ??= []

                  const { specifications = [], batches } = value

                  const goodsSpecification = Object.assign({}, specifications.find((item) => item.defaultService?.value) ?? specifications[0], {
                    goods: value
                  }) as EnocloudServiceDefinitions['GoodsSpecificationDto']

                  const init = formDataGoodsInit({ goods: value, goodsSpecification, batch: value.batches[0] })

                  this.form.data.stockCostAdjustmentGoods.push(init)
                }
              },
              delete: {
                click(index: number) {
                  this.form.data.stockCostAdjustmentGoods?.splice(index, 1)
                }
              },
              batch: {
                click() {
                  this.select.visible = true
                }
              }
            },
            goodsSpecification: {
              change(
                row: EnocloudInventoryDefinitions['StockCostAdjustmentGoodsDto'],
                specification: EnocloudInventoryDefinitions['GoodsSpecificationDto']
              ) {
                if (specification.id === row.goodsSpecification?.id) return
                specification.goods = Object.assign({}, row.goods)
                row.goodsSpecification = specification
              }
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      detail: {
        visible: false,
        confirm(res: number | undefined) {
          if (res) this.form.data.id = res
          this.form.get()
        }
      },
      select: {
        visible: false,
        confirm(rows: GoodsSelectionOption[]) {
          for (const row of rows) {
            const exist = this.form.data.stockCostAdjustmentGoods.find((item) => item.goods?.id === row.goods.id)
            if (!exist) {
              const init = {
                goods: row.goods,
                goodsSpecification: Object.assign({}, row.goodsSpecification, { goods: row.goods }),
                batch: row.batch
              } as EnocloudInventoryDefinitions['StockCostAdjustmentGoodsDto']
              this.form.data.stockCostAdjustmentGoods.push(init)
            }
          }
        }
      },
      logs: {
        visible: false
      },
      stock: {
        visible: false,
        confirm(res: number | undefined) {}
      }
    }
  }
})
</script>
